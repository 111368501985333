// src/on-document-ready.ts
function onDocumentReady(fn) {
  if (document.readyState === "interactive" || document.readyState === "complete") {
    fn();
  } else {
    document.addEventListener("DOMContentLoaded", () => {
      fn();
    });
  }
}

// src/parse-html.ts
function parseHTML(document2, html2) {
  if (typeof html2 !== "string")
    html2 = html2.toString();
  const template = document2.createElement("template");
  template.innerHTML = html2;
  return document2.importNode(template.content, true);
}
function parseHTMLElement(document2, html2) {
  const documentFragment = parseHTML(document2, html2);
  if (documentFragment.childElementCount !== 1) {
    throw new Error("Expected HTML to contain exactly one element");
  }
  return documentFragment.firstElementChild;
}

// src/encode-data.ts
import { encode, decode } from "js-base64";
import { html, unsafeHtml } from "@prairielearn/html";
function EncodedData(data, elementId) {
  const encodedData = unsafeHtml(encode(JSON.stringify(data)));
  return html`<script id="${elementId}" type="application/base64">
    ${encodedData}
  </script>`;
}
function decodeData(elementId) {
  const base64Data = document.getElementById(elementId)?.textContent;
  if (base64Data == null) {
    throw new Error(`No data found in element with ID "${elementId}"`);
  }
  const jsonData = decode(base64Data);
  const data = JSON.parse(jsonData);
  return data;
}

// src/template-from-attributes.ts
function templateFromAttributes(source, target, attributes) {
  Object.entries(attributes).forEach(([sourceAttribute, targetSelector]) => {
    const attributeValue = source.getAttribute(sourceAttribute);
    if (attributeValue == null) {
      console.error(`Attribute "${sourceAttribute}" not found on source element`);
      return;
    }
    const targets = target.querySelectorAll(targetSelector);
    if (targets.length === 0) {
      console.error(`No elements found matching selector "${targetSelector}"`);
      return;
    }
    targets.forEach((targetElement) => {
      if (targetElement instanceof HTMLInputElement) {
        if (targetElement.type === "checkbox") {
          const attributeParsed = JSON.parse(attributeValue);
          targetElement.checked = !!attributeParsed;
          targetElement.dispatchEvent(new Event("change", { bubbles: true }));
        } else {
          targetElement.value = attributeValue;
        }
      } else if (targetElement instanceof HTMLSelectElement) {
        const i = Array.from(targetElement.options).findIndex((o) => o.value === attributeValue);
        if (i >= 0) {
          targetElement.selectedIndex = i;
          targetElement.dispatchEvent(new Event("change", { bubbles: true }));
        } else {
          console.error(`Could not find option with value "${attributeValue}"`);
        }
      } else {
        targetElement.textContent = attributeValue;
      }
    });
  });
}
export {
  EncodedData,
  decodeData,
  onDocumentReady,
  parseHTML,
  parseHTMLElement,
  templateFromAttributes
};
